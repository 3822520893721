/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
import '../css/app.scss';

import $ from 'jquery';
import Slick from 'slick-carousel';
require('../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.js');
import SVGInject from '@iconfu/svg-inject';
import InfiniteGrid, {GridLayout, JustifiedLayout} from "@egjs/infinitegrid";

// Custom JS to override any defaults
// DO NOT change this .ready to native JS event listener
$(document).ready(function()
{
    // Dropdown Menus
    var dropdownOptions = {
        hover: true
    };
    M.Dropdown.init(document.querySelectorAll('nav .dropdown-trigger'), dropdownOptions);
	
	// Selects
    var selectOptions = {
        dropdownOptions: {container: document.body}
    };
    M.FormSelect.init(document.querySelectorAll('select:not(.browser-default)'), selectOptions);
	
	// Resize text boxes
    M.updateTextFields();
    
    // Sidenav
//    var sidenavOptions = {};
//    M.Sidenav.init(document.querySelectorAll('.sidenav'), sidenavOptions);

    // Slideshow
    $('.mvp_slideshow').not('.slick-initialized').slick();
	
	// Convert SVG imgs to actual SVG elements (i.e. social icons)
	SVGInject(document.querySelectorAll('img[src$=".svg"].js-convert-svg-to-paths'));
	
	// InfiniteGrid
    var infiniteGridOptions = {
        isOverflowScroll: false
    };
    if ($('.js-infinitegrid.grid').length) {
        $('.js-infinitegrid.grid').each(function(){
            let igg = new InfiniteGrid(this, infiniteGridOptions);
            igg.setLayout(GridLayout, {
                align: "center"
            });
            igg.layout();
        });
    }
    if ($('.js-infinitegrid.justified').length) {
        $('.js-infinitegrid.justified').each(function(){
            let igj = new InfiniteGrid(this, infiniteGridOptions);
            igj.setLayout(JustifiedLayout, {
                margin: 16,
                column: [2,4],
                maxSize: 600
            });
            igj.layout();
        });
    }
	
	// BESPOKE CUSTOMISATION RULES
    // List of Customisations/Groups to disable when specific ones are selected
    // Makes the fields non-mandatory to they can be bypassed when adding to basket
    // key is the trigger ID (must be a Customisation, not Group)
    // value is an array of IDs to disable (must be an array even if 1 item)
	var customisationRules = {};

	// Builds a list of Customisation (Groups) IDs to disable and enable
    // Afterwards runs enablers then disablers
    if (Object.keys(customisationRules).length)
    {
        var addToArray = function (toAction, disable) {
            toAction.forEach(function (i) {
                if (disable) {
                    if (!window.custsToDisable.includes(i)) {
                        window.custsToDisable.push(i);
                    }
                } else if (!window.custsToEnable.includes(i)) {
                    window.custsToEnable.push(i);
                }
            });
        };

        var handleInputs = function (toAction, entityId, disable) {
            toAction.forEach(function (custId) {
                var $thisItem = $('#mvp_custoptn_' + custId + '-i' + entityId);
                if (!$thisItem.length) {
                    // Check for customisation group
                    var $thisItem = $('#mvp_custogrp_' + custId + '-i' + entityId);
                }
                if ($thisItem) {
                    var $fileWrapper = false;
                    if ($thisItem.attr('type') === 'file') {
                        $fileWrapper = $thisItem.closest('.input-field.file-field');
                    }
                    if (disable) {
                        if (!$thisItem.attr('disabled')) {
                            if ($thisItem.attr('required')) {
                                $thisItem.addClass('had-required').removeAttr('required');
                            }
                            if ($fileWrapper) {
                                $fileWrapper.find('.btn').addClass('disabled');
                                $fileWrapper.find('input').attr('disabled', true);
                            }
                            // Clear any current value
                            if ($thisItem.is('select')) {
                                $thisItem.prop("selectedIndex", 0);
                            } else if ($thisItem.is('option')) {
								if ($thisItem.is(':selected')) {
									$thisItem.removeAttr('selected');
									$thisItem.closest('select').prop("selectedIndex", 0);
								}
                            } else if (($thisItem.attr('type') === 'radio' || $thisItem.attr('type') === 'checkbox')) {
								if ($thisItem.is(':checked')) {
									$thisItem.removeAttr('checked').trigger('change');
								}
                            } else {
                                // Standard text inputs
                                var thisVal = $thisItem.val();
                                $thisItem.val("");
                                if (thisVal && thisVal.length > 0) {
                                    $thisItem.trigger('change');
                                }
                            }
                            $thisItem.attr('disabled', true);
                        }
                    } else if ($thisItem.attr('disabled')) {
                        if ($thisItem.hasClass('had-required')) {
                            $thisItem.attr('required', true).removeClass('had-required');
                        }
                        if ($fileWrapper) {
                            $fileWrapper.find('.btn').removeClass('disabled');
                            $fileWrapper.find('input').removeAttr('disabled', true);
                        } else {
                            $thisItem.removeAttr('disabled');
                        }
                    }
                    // Reinit Materialize
                    if ($thisItem.is('select')) {
                        M.FormSelect.init($thisItem, selectOptions);
                    }
                    if ($thisItem.is('option')) {
                        M.FormSelect.init($thisItem.closest('select'), selectOptions);
                    }
                }
            });
        };

        $('.mvp_saleable_customisation').on('change', function() {
            window.custsToDisable = [];
            window.custsToEnable = [];
            var entityId = parseInt($(this).attr('data-entity-id'));
            if ($(this).is('select')) {
                // Get the entity ID from the selected option
                var entityId = parseInt($(this).find("option:selected").attr('data-entity-id'));
            }
            $.each(customisationRules, function(triggerId, toAction) {
                var $trigger = $('#mvp_custoptn_' + triggerId + '-i' + entityId);
                if ($trigger && customisationRules[triggerId]) {
                    var inputType = $trigger.attr('type');
                    if ($trigger.is('option')) {
                        if ($trigger.is(':selected')) {
                            addToArray(customisationRules[triggerId], true);
                        } else {
                            addToArray(customisationRules[triggerId], false);
                        }
                    } else if (inputType === 'checkbox' || inputType === 'radio') {
                        if ($trigger.is(':checked')) {
                            addToArray(customisationRules[triggerId], true);
                        } else {
                            addToArray(customisationRules[triggerId], false);
                        }
                    } else if ($trigger.is('select')) {
                        // We should never end up here because only options should be passed
                        console.log('ISSUE: <select> element passed as customisationRule instead of <option>');
                    } else {
                        // All other input types
                        if ($trigger.val()) {
                            addToArray(customisationRules[triggerId], true);
                        } else {
                            addToArray(customisationRules[triggerId], false);
                        }
                    }
                }
            });
            handleInputs(window.custsToEnable, entityId, false);
            handleInputs(window.custsToDisable, entityId, true);
        });
    }
});